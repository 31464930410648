import { Link } from "gatsby";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import ReactGA from "react-ga";
import BlogHero from "../../components/blog-hero";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Gist from "../../components/gist";
import DonationFooter from "../../components/donation-footer";
import AdBanner from "../../components/ad-banner";
import GumroadButton from "../../components/gumroad-button";

// reactjs-displaying-google-ads-without-external-packages

class View extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}

	render() {
		return (
			<Layout>
				<SEO
					title="React.js: Displaying Google Ads without using external packages"
					keywords={[
					]}
				/>

				{/* <div style={{marginTop: 30}}></div> */}
				{/* <AdBanner></AdBanner> */}
				{/* <div style={{marginTop: 30}}></div> */}

				{/* <Container>
					<div style={{ marginTop: "2rem" }} />
					<Row>
						<Col>
							<Link to="/blog"><Button variant="light">⬅ Back</Button></Link>
						</Col>
					</Row>
				</Container> */}

				<BlogHero
					header="React.js: Displaying Google Ads without using external packages"
					subHeader="How to display Google Ads on your React.js Website"
					maxWidth={650}
				/>

				<Container style={{ maxWidth: 650 }}>

					<Row>
						<Col>
							<p>
								<h2>Preface</h2>
							</p>
						</Col>
					</Row>

					<Row>
						<Col>
							<p>
							I have been working with React.js for quite a while now and all I can say about it, is that, it's a funny, funny framework when you start working with it. But once you get a hang of the structure and know how to work it, it will absolutely blow you mind (as it did mine). One of the aspects that I was particularly struggling with was integrating it with Google Ads.
							</p>
						</Col>
					</Row>

					<Row>
						<Col>
							<p>
							It's quite embarrassing but the actual reason I was struggling is because I did not understand how to use traditional document-window etc. specific Javascript code (which I have been writing since forever) with React.js. This was the case until I discovered that most of this code is written inside componentDidMount(). Once this knowledge was acquired, it was off to the races.
							</p>
						</Col>
					</Row>

					{/* <AdBanner /> */}

					<Row>
						<Col>
							<p>
							The code below creates a component called `GoogleAd`. You can directly copy this code into a file with a name like google-ad.js. Import it with import GoogleAd from '../components/google-ad'; and use it in your code like any other React.js component which is like {'<GoogleAd />'} (after changing the client and slot values, obviously). You should keep this in mind though. Google Ads do not work on localhost. Deploy them onto the Internet to see them flourish. </p>
						</Col>
					</Row>

					<Gist url="https://gist.githubusercontent.com/graphoarty/18ecbd685b567ccb84ae32838e0e8c83/raw/037d0948e515ecc92dc23dc46149509592d86383/gistfile1.txt"></Gist>

				</Container>

				<div style={{marginTop: 100}}></div>
				<DonationFooter />

			</Layout>
		);
	}
}

export default View;
