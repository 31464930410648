import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

class GumroadButton extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		// this.asyncScript('https://gumroad.com/js/gumroad.js');
	}

	// asyncScript = (scriptPath) => {

	// 	const script = document.createElement('script');
	// 	script.src = scriptPath;
	// 	script.async = false;
	// 	script.onl = () => {
	// 		this.setState({ loaded: true });
	// 	}
	// 	document.body.appendChild(script);

	// }

	render() {
		return (
			<div>
				<a className="gumroad-button" href="https://gum.co/360-player-tutorial-pdf" target="_blank"><Button variant="dark">Get PDF and Codebase</Button></a>
			</div>
		);
	}
}

export default GumroadButton;